import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from '@design-system/button';
import { HeadingBlock } from '@design-system/heading-block';
import { ScreenRecognitionContext } from '@phx-husky/screen-recognition';
import classNames from 'classnames';
import { buttonMapper } from 'mappers/button';
import { headingBlockMapper } from 'mappers/headingBlock';
import { imageMapper } from 'mappers/image';

import { Container } from 'layout';
import VideoModal from 'components/VideoModal';
import VideoPlayer from 'components/VideoPlayer';
import { getIconProps } from 'utils/customIcons';

import { IFeaturedVideoProps } from './models';

import './FeaturedVideo.scss';

const FeaturedVideo: FC<IFeaturedVideoProps> = ({
  data: [{ heading_block, image, mobileImage, video_id, full_video_id }],
  cta_watch,
  modalCloseAriaLabel,
}) => {
  const [isVideoReady, setVideoReady] = useState<boolean>(false);
  const [isModalShow, setIsModalShow] = useState<boolean>(false);
  const { isMobile } = useContext(ScreenRecognitionContext);

  useEffect(() => {
    const timer = setTimeout(() => setVideoReady(true), 3 * 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleModalVisibility = useCallback(() => {
    setIsModalShow((prev) => !prev);
  }, []);

  const ctaWatchBtn = useMemo(() => buttonMapper(cta_watch), [cta_watch]);

  return (
    <div
      data-testid="FeaturedVideo"
      className={classNames('featured-video', {
        'video-ready': isVideoReady,
      })}
    >
      <div className="featured-video__image-wrapper">
        <div className="featured-video__image">
          {image?.image && mobileImage?.image ? (
            <div>
              {
                imageMapper({
                  ...(isMobile ? { ...mobileImage } : { ...image }),
                })?.element
              }
            </div>
          ) : null}
        </div>

        {isVideoReady && video_id ? (
          <div className="featured-video__video">
            <VideoPlayer videoId={video_id} handleVideoReady={setVideoReady} isFullWidth />
          </div>
        ) : null}
      </div>
      <Container className="featured-video__container">
        <div className="featured-video__content">
          <HeadingBlock
            {...headingBlockMapper(heading_block)}
            wrapping="default"
            headlineVariant="headlineM"
            className="featured-video__heading-block"
          />
          <div className="featured-video__cta-wrapper">
            <Button
              {...ctaWatchBtn}
              {...getIconProps(ctaWatchBtn, 'iconPrefix', 'play3')}
              className="featured-video__cta"
              onClick={handleModalVisibility}
            />
          </div>
        </div>
      </Container>

      {full_video_id || video_id ? (
        <VideoModal
          isModalShow={isModalShow}
          closeModal={handleModalVisibility}
          videoId={full_video_id || video_id}
          modalCloseAriaLabel={modalCloseAriaLabel}
        />
      ) : null}
    </div>
  );
};

export default FeaturedVideo;
