import React, { FC, useContext, useState } from 'react';
import { Carousel } from '@design-system/carousel';
import { Typography } from '@design-system/typography';
import { ScreenRecognitionContext } from '@phx-husky/screen-recognition';
import classNames from 'classnames';
import { carouselMapper } from 'mappers/carousel';
import { typographyMapper } from 'mappers/typography';

import VideoCarouselItem from 'components/VideoCarouselItem';

import { IVideoCarouselProps } from './models';

import './VideoCarousel.scss';

const VideoCarousel: FC<IVideoCarouselProps> = ({
  title,
  items,
  carousel,
  cta_watch,
  mobilePreferredCardSize,
  onFocusCallback,
  onMouseLeaveCallback,
  modalCloseAriaLabel,
  isHideVideo,
  centerSlides,
  itemDirection = 'horizon',
}) => {
  const { isMobile } = useContext(ScreenRecognitionContext);
  const [currentActiveCard, setCurrentActiveCard] = useState<number>(0);

  return (
    <div
      data-testid="VideoCarousel"
      className={classNames('video-carousel', {
        'has-active-card': currentActiveCard,
        'center-slides': centerSlides,
      })}
    >
      {title?.content ? (
        <Typography {...typographyMapper(title)} className="video-carousel__title" />
      ) : null}

      <Carousel
        {...carouselMapper(carousel)}
        {...(mobilePreferredCardSize && isMobile
          ? { preferredCardSize: mobilePreferredCardSize }
          : {})}
        className={classNames('video-carousel-wrapper', `video-carousel-wrapper--${itemDirection}`)}
        items={items.map((item, index) => ({
          caption: item.heading_block.headline!,
          children: (
            <VideoCarouselItem
              item={item}
              cta_watch={cta_watch}
              onFocusCallback={onFocusCallback}
              onMouseLeaveCallback={onMouseLeaveCallback}
              currentActiveCard={currentActiveCard}
              itemDirection={itemDirection}
              handleCurrentActiveCard={setCurrentActiveCard}
              itemIndex={index + 1}
              modalCloseAriaLabel={modalCloseAriaLabel}
              isHideVideo={isHideVideo}
            />
          ),
        }))}
      />
    </div>
  );
};

export default VideoCarousel;
