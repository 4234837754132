import React, { FC, useEffect } from 'react';
import { PromoModal } from '@design-system/promo-modal';

import VideoPlayer from 'components/VideoPlayer';

import { IVideoModalProps } from './models';

import './VideoModal.scss';

const VideoModal: FC<IVideoModalProps> = ({
  isModalShow,
  closeModal,
  videoId,
  modalCloseAriaLabel,
}) => {
  useEffect(() => {
    document.body.classList.toggle('with-modal', isModalShow);
  }, [isModalShow]);

  return videoId ? (
    <PromoModal
      close={closeModal}
      closeAriaLabel={modalCloseAriaLabel}
      closeModal={closeModal}
      isShowing={isModalShow}
      disableOnClickClose
      className="video-modal"
    >
      <div data-testid="VideoModal" className="video-modal__content">
        <VideoPlayer videoId={videoId} withControls />
      </div>
    </PromoModal>
  ) : null;
};

export default VideoModal;
