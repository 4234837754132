import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@design-system/button';
import { HeadingBlock } from '@design-system/heading-block';
import classNames from 'classnames';
import { buttonMapper } from 'mappers/button';
import { headingBlockMapper } from 'mappers/headingBlock';
import { imageMapper } from 'mappers/image';

import VideoModal from 'components/VideoModal';
import VideoPlayer from 'components/VideoPlayer';
import { getIconProps } from 'utils/customIcons';

import { IVideoCarouselItemProps } from './models';

import './VideoCarouselItem.scss';

const ActiveClassName = 'active';
const PrevClassName = 'swiper-slide-prev';

/**
 * currentActiveCard & handleCurrentActiveCard * itemIndex - are used for accessability
 */
const VideoCarouselItem: FC<IVideoCarouselItemProps> = ({
  item,
  cta_watch,
  itemDirection,
  onFocusCallback,
  onMouseLeaveCallback,
  currentActiveCard,
  handleCurrentActiveCard,
  itemIndex,
  modalCloseAriaLabel,
  isHideVideo,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isHiddenCard, setIsHiddenCard] = useState<boolean>(false);
  const [isActiveCard, setIsActiveCard] = useState<boolean>(false);
  const [isVideoReady, setVideoReady] = useState<boolean>(false);
  const [isModalShow, setIsModalShow] = useState<boolean>(false);

  useEffect(() => {
    setIsHiddenCard(Boolean(wrapperRef?.current?.parentElement?.getAttribute('aria-hidden')));
  }, [currentActiveCard]);

  const onFocus = useCallback(() => {
    const parentNode = wrapperRef?.current?.parentElement;

    if (parentNode?.classList.contains(PrevClassName)) {
      return;
    }

    let isPrevElementExist = false;

    // check all children in the carousel
    if (parentNode?.parentElement?.children) {
      Array.from(parentNode?.parentElement?.children).forEach((child) => {
        if (child?.classList.contains(PrevClassName)) {
          isPrevElementExist = true;
        }
      });
    }

    if (!isPrevElementExist) {
      onFocusCallback();
    }

    handleCurrentActiveCard(itemIndex);
    setIsActiveCard(true);

    if (!wrapperRef?.current?.classList.contains(ActiveClassName)) {
      wrapperRef?.current?.classList.add(ActiveClassName);
    }

    if (!parentNode?.classList.contains(ActiveClassName)) {
      parentNode?.classList.add(ActiveClassName);
    }
  }, [onFocusCallback, handleCurrentActiveCard, itemIndex]);

  const onMouseLeave = useCallback(() => {
    const parentNode = wrapperRef?.current?.parentElement;

    if (parentNode?.classList.contains(PrevClassName)) {
      return;
    }

    handleCurrentActiveCard(0);
    onMouseLeaveCallback();
    setIsActiveCard(false);
    setVideoReady(false);

    if (wrapperRef?.current?.classList.contains(ActiveClassName)) {
      wrapperRef.current.classList.remove(ActiveClassName);
    }

    if (parentNode?.classList.contains(ActiveClassName)) {
      parentNode?.classList.remove(ActiveClassName);
    }
  }, [onMouseLeaveCallback, handleCurrentActiveCard]);

  const handleModalVisibility = useCallback(() => {
    setIsModalShow((prev) => !prev);
    onMouseLeave();
  }, [onMouseLeave]);

  const ctaWatchBtn = useMemo(() => buttonMapper(cta_watch), [cta_watch]);

  return (
    <div
      data-testid="VideoCarouselItem"
      className={classNames('video-carousel-item', `video-carousel-item--${itemDirection}`)}
      role="presentation"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={isHiddenCard ? -1 : 0}
      ref={wrapperRef}
      onFocus={onFocus}
      onMouseEnter={onFocus}
      onMouseLeave={onMouseLeave}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(isHideVideo ? { onBlur: onMouseLeave } : {})}
    >
      <div className="video-carousel-item__card video-carousel-item__card--front">
        {item?.preview_image?.image ? (
          <div className="video-carousel-item__top">
            {imageMapper(item?.preview_image)?.element}
          </div>
        ) : null}
      </div>

      <div className="video-carousel-item__card video-carousel-item__card--back">
        {item?.preview_image?.image ? (
          <div
            className={classNames('video-carousel-item__top', {
              'active-card': isActiveCard,
              'video-ready': isVideoReady,
              'no-video': isHideVideo,
            })}
          >
            {imageMapper(item?.preview_image)?.element}

            {!isHideVideo && isActiveCard && item?.video_id ? (
              <div className="video-carousel-item__video">
                <VideoPlayer videoId={item.video_id} handleVideoReady={setVideoReady} />
              </div>
            ) : null}

            {!isHideVideo ? (
              <div className="video-carousel-item__cta-wrapper">
                <Button
                  {...ctaWatchBtn}
                  {...getIconProps(ctaWatchBtn, 'iconPrefix', 'play3')}
                  className="video-carousel-item__cta"
                  onBlur={onMouseLeave}
                  onClick={handleModalVisibility}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="video-carousel-item__content">
          <HeadingBlock
            {...headingBlockMapper(item.heading_block)}
            wrapping="default"
            headlineVariant="headlineM"
            className="video-carousel-item__heading-block"
          />
        </div>
      </div>

      {item?.full_video_id || item?.video_id ? (
        <VideoModal
          isModalShow={isModalShow}
          closeModal={handleModalVisibility}
          videoId={item?.full_video_id || item?.video_id}
          modalCloseAriaLabel={modalCloseAriaLabel}
        />
      ) : null}
    </div>
  );
};

export default VideoCarouselItem;
