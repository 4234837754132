import React, { FC } from 'react';
import { Typography } from '@design-system/typography';
import { imageMapper } from 'mappers/image';
import { typographyMapper } from 'mappers/typography';

import { Container } from 'layout';

import { IVideoListingBannerProps } from './models';

import './VideoListingBanner.scss';

const VideoListingBanner: FC<IVideoListingBannerProps> = ({ header, text, image }) => (
  <div data-testid="VideoListingBanner" className="video-listing-banner">
    <Container className="video-listing-banner__container">
      <div className="video-listing-banner__text-wrapper">
        <Typography {...typographyMapper(header)} className="video-listing-banner__title" />
        <Typography {...typographyMapper(text)} className="video-listing-banner__text" />
      </div>

      <div className="video-listing-banner__image">{imageMapper(image)?.element}</div>
    </Container>
  </div>
);

export default VideoListingBanner;
