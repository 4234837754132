import React, { FC, useCallback, useRef } from 'react';
import classNames from 'classnames';

import VideoCarousel from 'components/VideoCarousel';

import { IVideoCarouselSectionProps } from './models';

import './VideoCarouselSection.scss';

const ActiveClassName = 'active';

const VideoCarouselSection: FC<IVideoCarouselSectionProps> = ({
  trending_title,
  trending_items,
  trending_carousel,
  soon_carousel,
  soon_items,
  soon_title,
  all_carousel,
  all_items,
  all_title,
  cta_watch,
  allDefaultVideos,
  modalCloseAriaLabel,
  hide_all_videos_block,
  centerSlides,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onFocusCallback = useCallback(() => {
    if (!wrapperRef?.current?.classList.contains(ActiveClassName)) {
      wrapperRef?.current?.classList.add(ActiveClassName);
    }
  }, []);

  const onMouseLeaveCallback = useCallback(() => {
    if (wrapperRef?.current?.classList.contains(ActiveClassName)) {
      wrapperRef?.current?.classList.remove(ActiveClassName);
    }
  }, []);

  return (
    <div
      data-testid="VideoCarouselSection"
      className={classNames('video-carousel-section', {
        'center-slides': centerSlides,
      })}
      ref={wrapperRef}
    >
      {!hide_all_videos_block &&
      cta_watch &&
      (all_items?.length || allDefaultVideos.nodes?.length) ? (
        <VideoCarousel
          title={all_title}
          items={all_items?.length ? all_items : allDefaultVideos.nodes}
          carousel={all_carousel}
          cta_watch={cta_watch}
          mobilePreferredCardSize={225}
          onFocusCallback={onFocusCallback}
          onMouseLeaveCallback={onMouseLeaveCallback}
          modalCloseAriaLabel={modalCloseAriaLabel}
        />
      ) : null}

      {soon_items?.length && cta_watch ? (
        <VideoCarousel
          title={soon_title}
          items={soon_items}
          carousel={soon_carousel}
          cta_watch={cta_watch}
          mobilePreferredCardSize={145}
          itemDirection="vertical"
          onFocusCallback={onFocusCallback}
          onMouseLeaveCallback={onMouseLeaveCallback}
          modalCloseAriaLabel={modalCloseAriaLabel}
          isHideVideo
        />
      ) : null}

      {trending_items?.length && cta_watch ? (
        <VideoCarousel
          title={trending_title}
          items={trending_items}
          carousel={trending_carousel}
          cta_watch={cta_watch}
          mobilePreferredCardSize={225}
          onFocusCallback={onFocusCallback}
          onMouseLeaveCallback={onMouseLeaveCallback}
          modalCloseAriaLabel={modalCloseAriaLabel}
          centerSlides={centerSlides}
        />
      ) : null}
    </div>
  );
};

export default VideoCarouselSection;
