import React, { FC, ReactElement, useContext } from 'react';
import { graphql } from 'gatsby';
import { ScreenRecognitionContext } from '@phx-husky/screen-recognition';

import { Container } from 'layout';
import { Layout } from 'layout/Layout';
import FeaturedVideo from 'components/FeaturedVideo';
import VideoCarouselSection from 'components/VideoCarouselSection';
import VideoListingBanner from 'components/VideoListingBanner';
import { getBuildProcessInfo } from 'utils/buildProcess';
import withContentstackPreview from 'utils/livePreview';

import type { TVideoListingPageData } from './models';

import './VideoListingPage.scss';

const Wrapper: FC<{ centerSlides: boolean; children: ReactElement }> = ({
  centerSlides,
  children,
}) => {
  if (centerSlides) {
    return <Container>{children}</Container>;
  }

  return children;
};

/**
 * Private template
 */
export const VideoListingPage: FC<TVideoListingPageData> = ({
  data: {
    pageData,
    allDefaultVideos,
    footer,
    header,
    headerAccount,
    headerNavigationLoggedIn,
    footerNavigationLoggedIn,
    redirects,
  },
  pageContext,
  location,
}) => {
  getBuildProcessInfo(pageContext);
  const {
    seo,
    featured_video,
    banner,
    hide_all_videos_block,
    modal_close_aria_label,
    cta_watch,
    trending_title,
    trending_items,
    trending_carousel,
    soon_carousel,
    soon_items,
    soon_title,
    all_carousel,
    all_items,
    all_title,
  } = pageData;
  const { isXl } = useContext(ScreenRecognitionContext);

  const centerSlides = Boolean(
    !soon_items?.length && hide_all_videos_block && trending_items?.length < 5
  );

  return (
    <Layout
      seo={seo}
      pageUid={pageContext.uid}
      className="video-listing-page"
      footer={footer}
      footerNavigationLoggedIn={footerNavigationLoggedIn}
      header={header}
      headerNavigationLoggedIn={headerNavigationLoggedIn}
      isHeaderOnTopContent
      headerAccount={headerAccount}
      headerMode="transparent"
      redirects={redirects}
      location={location.pathname}
      privatePage
      data-testid="VideoListingPage"
    >
      {banner?.[0] ? (
        <VideoListingBanner
          header={banner[0].header}
          text={banner[0].text}
          image={banner[0].image}
        />
      ) : null}

      {featured_video?.length && cta_watch ? (
        <FeaturedVideo
          data={featured_video}
          cta_watch={cta_watch}
          modalCloseAriaLabel={modal_close_aria_label}
        />
      ) : null}

      <Wrapper centerSlides={centerSlides}>
        <VideoCarouselSection
          trending_title={trending_title}
          trending_carousel={{
            ...trending_carousel,
            variant: centerSlides && isXl ? 'fluid' : trending_carousel.variant,
          }}
          trending_items={trending_items}
          soon_carousel={soon_carousel}
          soon_items={soon_items}
          soon_title={soon_title}
          all_carousel={all_carousel}
          all_items={all_items}
          all_title={all_title}
          cta_watch={cta_watch}
          allDefaultVideos={allDefaultVideos}
          modalCloseAriaLabel={modal_close_aria_label}
          hide_all_videos_block={hide_all_videos_block}
          centerSlides={centerSlides}
        />
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String, $locale: String) {
    pageData: contentstackFinishVideoListingTemplate(id: { eq: $id }) {
      uid
      seo {
        ...SeoFragment
      }
      fields {
        ...TemplateFieldsFragment
      }
      featured_video {
        ...FeaturedVideoFragment
      }
      banner {
        ...VideoListingBannerFragment
      }
      hide_all_videos_block
      modal_close_aria_label
      cta_watch {
        ...ButtonFragment
      }
      trending_title {
        ...TypographyFragment
      }
      trending_items {
        ...VideoCarouselItemVerticalFragment
      }
      trending_carousel {
        ...CarouselUIFragment
      }
      soon_title {
        ...TypographyFragment
      }
      soon_items {
        ...VideoCarouselItemHorizonFragment
      }
      soon_carousel {
        ...CarouselUIFragment
      }
      all_title {
        ...TypographyFragment
      }
      all_items {
        ...VideoCarouselItemVerticalFragment
      }
      all_carousel {
        ...CarouselUIFragment
      }
    }
    footer: contentstackFinishFooter(publish_details: { locale: { eq: $locale } }) {
      ...FooterFragment
    }
    header: contentstackFinishHeader(publish_details: { locale: { eq: $locale } }) {
      ...HeaderFragment
    }
    headerAccount: contentstackFinishHeaderAccount(publish_details: { locale: { eq: $locale } }) {
      ...HeaderAccountFragment
    }
    headerNavigationLoggedIn: contentstackFinishNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...HeaderNavigationLoggedInFragment
    }
    footerNavigationLoggedIn: contentstackFinishFooterNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...FooterNavigationLoggedInFragment
    }
    redirects: contentstackFinishGuard(publish_details: { locale: { eq: $locale } }) {
      ...AuthGuardFragment
    }
    allDefaultVideos: allContentstackFinishVideoComponent(filter: { video_id: { ne: null } }) {
      nodes {
        ...VideoCarouselItemVerticalFragment
      }
    }
  }
`;

export default withContentstackPreview(
  VideoListingPage,
  `${process.env.GATSBY_CONTENTSTACK_CONTENT_TYPES_PREFIX}_video_listing_template`
);
